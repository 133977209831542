<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="dialog"
      max-width="400px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title>
          <span> Choose an element </span>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" @click.stop="closeform" class="ma-1">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <br />
        <v-card-text>
          <v-list-item-group density="compact">
            <v-list-item
              dense
              v-for="(item, i) in list"
              :key="i"
              :value="item.code"
              @click="select(item)"
            >
              <div style="width: 50px">
                <v-list-item-content class="pt-0">
                  <v-list-item-title v-text="item.code"></v-list-item-title>
                </v-list-item-content>
              </div>
              <v-list-item-content class="pt-0">
                <v-list-item-title v-text="item.label"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-form>
</template>
<script>
export default {
  props: {
    dialog: Boolean,
    list: [],
  },
  data() {
    return {};
  },

  async mounted() {},
  async created() {},
  methods: {
    select(item) {
      this.$emit("close", item);
    },
    closeform() {
      this.$emit("close");
    },
  },
};
</script>
